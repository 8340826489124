@import url("https://fonts.googleapis.com/css2?family=VT323&display=swap");

.background {
  width: 100%;
  height: 100%;
  background-color: rgb(61, 59, 59);
  /* background: radial-gradient(
    circle at center,
    rgb(49, 47, 47) 0,
    rgb(72, 70, 70),
    rgb(94, 90, 90) 100%
  ); */
  display: flex;
  flex-direction: column;
  justify-content: space-evenly;
  align-items: center;
  font-family: "VT323", monospace;
  color: white;
}
.loading {
  width: 100%;
  height: 100%;
  background-color: rgb(61, 59, 59);
  display: flex;
  justify-content: center;
  align-items: center;
}
.lds-spinner {
  color: official;
  display: inline-block;
  position: relative;
  width: 80px;
  height: 80px;
}
.lds-spinner div {
  transform-origin: 40px 40px;
  animation: lds-spinner 1.2s linear infinite;
}
.lds-spinner div:after {
  content: " ";
  display: block;
  position: absolute;
  top: 3px;
  left: 37px;
  width: 6px;
  height: 18px;
  border-radius: 20%;
  background: #fff;
}
.lds-spinner div:nth-child(1) {
  transform: rotate(0deg);
  animation-delay: -1.1s;
}
.lds-spinner div:nth-child(2) {
  transform: rotate(30deg);
  animation-delay: -1s;
}
.lds-spinner div:nth-child(3) {
  transform: rotate(60deg);
  animation-delay: -0.9s;
}
.lds-spinner div:nth-child(4) {
  transform: rotate(90deg);
  animation-delay: -0.8s;
}
.lds-spinner div:nth-child(5) {
  transform: rotate(120deg);
  animation-delay: -0.7s;
}
.lds-spinner div:nth-child(6) {
  transform: rotate(150deg);
  animation-delay: -0.6s;
}
.lds-spinner div:nth-child(7) {
  transform: rotate(180deg);
  animation-delay: -0.5s;
}
.lds-spinner div:nth-child(8) {
  transform: rotate(210deg);
  animation-delay: -0.4s;
}
.lds-spinner div:nth-child(9) {
  transform: rotate(240deg);
  animation-delay: -0.3s;
}
.lds-spinner div:nth-child(10) {
  transform: rotate(270deg);
  animation-delay: -0.2s;
}
.lds-spinner div:nth-child(11) {
  transform: rotate(300deg);
  animation-delay: -0.1s;
}
.lds-spinner div:nth-child(12) {
  transform: rotate(330deg);
  animation-delay: 0s;
}
@keyframes lds-spinner {
  0% {
    opacity: 1;
  }
  100% {
    opacity: 0;
  }
}

.background a {
  text-decoration: none;
  /* font-size: 55px; */
  font-size: 4.5vmin;
  color: white;
  animation: 1s fade infinite alternate;
  vertical-align: top;
  border: 1px solid white;
  background-color: rgb(35, 35, 131);
  box-shadow: 6px 6px 0 0 white;
  padding: 8px;
}
.title h1 {
  /* font-size: 100px; */
  font-size: 8vmin;
  text-align: center;
  margin-left: 1px;
  margin-right: 1px;
  /* animation: 1s fade infinite alternate; */
}
.title h2 {
  /* font-size: 60px; */
  font-size: 5.5vmin;
  text-align: center;
  /* animation: 1s fade infinite alternate; */
}

@keyframes fade {
  0% {
    opacity: 0.1;
  }
  100% {
    opacity: 1;
  }
}

@media (orientation: portrait) {
  .title h1 {
    font-size: 6vmax;
  }
  .title h2 {
    font-size: 5.5vmax;
  }
  .background a {
    font-size: 5vmax;
  }
}
@media (max-width: 550px) {
  .title h1 {
    font-size: 7vmax;
  }
  .title h2 {
    font-size: 4vmax;
  }
  .background a {
    font-size: 4vmax;
  }
}

/* @media (max-width: 2400px) {
  .title h1 {
    font-size: 80px;
  }
  .title h2 {
    font-size: 45px;
  }
  .background a {
    font-size: 40px;
  }
}
@media (max-width: 1100px) {
  .title h1 {
    font-size: 70px;
  }
  .title h2 {
    font-size: 40px;
  }
  .background a {
    font-size: 35px;
  }
}
@media (max-width: 700px) {
  .title h1 {
    font-size: 60px;
  }
  .title h2 {
    font-size: 35px;
  }
  .background a {
    font-size: 30px;
  }
}
@media (max-width: 500px) {
  .title h1 {
    font-size: 50px;
  }
  .title h2 {
    font-size: 25px;
  }
  .background a {
    font-size: 20px;
  }
} */
