@import url("https://fonts.googleapis.com/css2?family=VT323&display=swap");
/* * {
  border: 1px solid red;
} */
.container {
  width: 100%;
  height: 100%;
  background: url("../../images/background4.gif") no-repeat;
  background-size: cover;
  border-radius: 10px;
  display: flex;
  justify-content: center;
  align-items: center;
  position: relative;
}
.profilePhoto {
  position: absolute;
  bottom: 0%;
  right: 0%;
  height: auto;
  width: 14vmax;
}
.profilePhoto img {
  width: 100%;
  height: 100%;
  border-radius: 50%;
}

.infoContainer {
  height: 80%;
  width: 80%;
  background-color: rgb(0, 0, 0, 0.9);
  border: 1px solid white;
  border-radius: 20px;
  display: flex;
  justify-content: center;
  align-items: center;
  font-family: "VT323", monospace;
  position: relative;
}
.exitBtn {
  height: 7%;
  width: 5%;
  position: absolute;
  top: 8%;
  right: 4%;
  display: flex;
  justify-content: center;
  align-items: center;
}
.exitBtn img {
  height: 100%;
  width: 100%;
}

.info {
  width: 100%;
  height: 50%;
  display: flex;
  justify-content: space-evenly;
  align-items: center;
}

.profile {
  height: 100%;
  width: 30%;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
}
.profile img {
  height: auto;
  width: 40%;
  animation: 1s bounce infinite alternate;
  cursor: pointer;
}
.profile h1 {
  color: white;
  font-size: 5vmin;
  margin: 0;
  animation: 1s bounce infinite alternate;
  cursor: pointer;
  text-align: center;
}

.experience {
  height: 100%;
  width: 30%;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
}
.experience img {
  height: auto;
  width: 40%;
  animation: 1s bounce infinite alternate;
  cursor: pointer;
}
.experience h1 {
  margin: 0;
  color: white;
  font-size: 5vmin;
  text-align: center;
  animation: 1s bounce infinite alternate;
  cursor: pointer;
}

.contact {
  height: 100%;
  width: 30%;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
}
.contact img {
  height: auto;
  width: 40%;
  animation: 1s bounce infinite alternate;
  cursor: pointer;
}
.contact h1 {
  color: white;
  font-size: 5vmin;
  margin: 0;
  animation: 1s bounce infinite alternate;
  cursor: pointer;
}

@keyframes bounce {
  0% {
    transform: translateY(-8%);
  }
  100% {
    transform: translateY(0%);
  }
}

/* Media Query */
@media (orientation: portrait) {
  .infoContainer {
    height: 90%;
    width: 85%;
  }
  .exitBtn {
    height: 35px;
    width: 35px;
    top: 3%;
  }
  .info {
    flex-direction: column;
    height: 90%;
  }
  .profile {
    height: 33%;
    width: 100%;
  }
  .profile img {
    height: auto;
    width: 30%;
    min-width: 70px;
    max-width: 250px;
  }
  .profile h1 {
    font-size: 4vmax;
  }
  .experience {
    height: 33%;
    width: 100%;
  }
  .experience img {
    height: auto;
    width: 30%;
    min-width: 70px;
    max-width: 250px;
  }
  .experience h1 {
    font-size: 4vmax;
  }
  .contact {
    height: 33%;
    width: 100%;
  }
  .contact img {
    height: auto;
    width: 30%;
    min-width: 70px;
    max-width: 250px;
  }
  .contact h1 {
    font-size: 4vmax;
  }
}
