@import url("https://fonts.googleapis.com/css2?family=Roboto+Mono&family=VT323&display=swap");
.container {
  height: 80%;
  width: 80%;
  background-color: rgb(0, 0, 0);
  border: 1px solid white;
  border-radius: 20px;
  font-family: "VT323", monospace;
  position: relative;
  color: white;
}

.exitBtn {
  height: auto;
  width: 3.5vmax;
  position: absolute;
  top: 5%;
  right: 4%;
  display: flex;
  justify-content: center;
  align-items: center;
  cursor: pointer;
}
.exitBtn img {
  height: 100%;
  width: 100%;
}
.container h1 {
  position: absolute;
  top: 5%;
  margin: 0;
  left: 4%;
  font-size: 7vmin;
}
.information {
  position: absolute;
  top: 18%;
  left: 4%;
  right: 4%;
  font-size: 2.9vmin;
  bottom: 5%;
  overflow-y: scroll;
  font-family: "Roboto Mono", monospace;
}
.information h3,
h4 {
  margin: 0;
  display: inline;
  font-weight: bold;
}
.information::-webkit-scrollbar {
  width: 0.5rem;
}

.information::-webkit-scrollbar-thumb {
  background-color: white; /* color of the scroll thumb */
  border-radius: 50px; /* roundness of the scroll thumb */
}

@media (orientation: portrait) {
  .container {
    height: 90%;
    width: 85%;
  }
  .exitBtn {
    top: 3%;
  }
  .container h1 {
    top: 3%;
    font-size: 4.35vmax;
  }
  .information {
    top: 11%;
  }
  .information::-webkit-scrollbar {
    width: 0.3rem;
  }
  .information ul {
    padding-left: 15px;
  }
  .information h3 {
    display: block;
  }
}
