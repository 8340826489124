/* * {
  border: 1px solid red;
} */

.background {
  position: absolute;
  background: url("../../images/4.png") no-repeat;
  background-size: cover;
  right: 0;
  left: 0;
  top: 0;
  bottom: 0;
  display: flex;
  justify-content: center;
  align-items: flex-end;
}
.container {
  position: absolute;
  left: 0;
  top: 0;
  right: 0;
  bottom: 0;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
}
.computerStand {
  width: 50%;
  height: 12%;
  background-color: beige;
  border-bottom: 2px solid black;
  border-right: 2px solid black;
  border-left: 2px solid black;
  border-radius: 4px;
  display: flex;
  justify-content: space-evenly;
  align-items: flex-end;
  margin-bottom: 2.5%;
}
.computerStand div {
  height: 42%;
  width: 0.7%;
  background-color: rgba(0, 0, 0, 0.5);
}

.monitor {
  width: 100%;
  height: 100%;
  background-color: beige;
  border-radius: 10px;
  border: 2px solid black;
  display: grid;
  grid-template-columns: repeat(20, 1fr);
  grid-template-rows: repeat(20, 1fr);
}

.onoff {
  grid-area: 19 / 18/ 21/ 19;
  display: flex;
  justify-content: flex-end;
  align-items: center;
}
.button {
  width: 70%;
  height: 40%;
  background-color: beige;
  border: 1px solid black;
  box-shadow: 0 0 0 5px rgb(0, 0, 0, 0.3);
  border-radius: 2px;
}
.light {
  grid-area: 19/19/21/20;
  display: flex;
  align-items: center;
}
.light div {
  width: 25%;
  height: 20%;
  border-radius: 50%;
  margin: 30% 30%;
  border: 1px solid black;
  /* background: radial-gradient(circle at 25% 25%, #5cabff, #000);
    background-color: rgb(3, 239, 3); */
  position: relative;
  background: radial-gradient(
    circle at 50% 120%,
    #bdebbe,
    #45e059 10%,
    #049c13 80%,
    #036a1e 100%
  );
  animation: 0.1s flicker infinite alternate;
}
.light div:before {
  content: "";
  position: absolute;
  top: 1%;
  left: 5%;
  width: 90%;
  height: 90%;
  border-radius: 50%;
  background: radial-gradient(
    circle at 50% 0px,
    #ffffff,
    rgba(255, 255, 255, 0) 58%
  );
  filter: blur(5px);
  z-index: 2;
}
@keyframes flicker {
  0% {
    opacity: 1;
  }
  100% {
    opacity: 0.8;
  }
}
.otherButton1 {
  grid-area: 19/17/21/18;
  display: flex;
  justify-content: center;
  align-items: center;
}
.otherButton1 div {
  width: 45%;
  height: 35%;
  border-radius: 50%;
  background-color: beige;
  border: 1px solid black;
  box-shadow: 0 0 0 4px rgb(0, 0, 0, 0.3);
}
.otherButton2 {
  grid-area: 19/16/21/17;
  display: flex;
  justify-content: flex-end;
  align-items: center;
}
.otherButton2 div {
  width: 45%;
  height: 35%;
  border-radius: 50%;
  background-color: beige;
  border: 1px solid black;
  box-shadow: 0 0 0 4px rgb(0, 0, 0, 0.3);
}

.frame {
  grid-area: 2 / 2 / 19 / 20;
  background-color: black;
  border-radius: 4px;
  display: grid;
  grid-template-columns: repeat(16, 1fr);
  grid-template-rows: repeat(16, 1fr);
}

.screen {
  grid-area: 2/2/16/16;
  background-color: rgb(61, 59, 59);
  border-radius: 10px;
  position: relative;
}

.arrowUp {
  grid-area: 17/1/9/17;
  position: relative;
  animation: 0.2s frameflicker infinite alternate;
}

.arrowUp:before {
  content: "";
  position: absolute;
  display: block;
  top: 0;
  bottom: 0;
  width: 100%;
  height: 100%;
  background: rgb(246, 246, 234);
  /* -webkit-clip-path: polygon(100% 0, 100% 100%, 0 50%); */
  clip-path: polygon(100% 99.6%, 50% 0, 0 99.6%);
}

.arrowDown {
  grid-area: 1/1/9/17;
  position: relative;
  animation: 0.2s frameflicker infinite alternate;
}
.arrowDown:before {
  content: "";
  position: absolute;
  display: block;
  top: 0;
  bottom: 0;
  width: 100%;
  height: 100%;
  background: rgb(176, 176, 159);
  /* -webkit-clip-path: polygon(100% 0, 100% 100%, 0 50%); */
  clip-path: polygon(100% 0.4%, 50% 100%, 0 0.4%);
}

.arrowRight {
  grid-area: 1/1/17/9;
  position: relative;
  animation: 0.2s frameflicker infinite alternate;
}

.arrowRight:before {
  content: "";
  position: absolute;
  display: block;
  top: 0;
  bottom: 0;
  width: 100%;
  height: 100%;
  background: rgb(246, 246, 234);
  /* -webkit-clip-path: polygon(100% 0, 100% 100%, 0 50%); */
  clip-path: polygon(0.2% 99.6%, 0.2% 0.4%, 100% 50%);
}

.arrowLeft {
  grid-area: 1/17/17/9;
  position: relative;
  animation: 0.2s frameflicker infinite alternate;
}

.arrowLeft:before {
  content: "";
  position: absolute;
  display: block;
  top: 0;
  bottom: 0;
  width: 100%;
  height: 100%;
  background: beige;
  clip-path: polygon(99.8% 99.6%, 99.8% 0.4%, 0 50%);
}
@keyframes frameflicker {
  0% {
    opacity: 1;
  }
  100% {
    opacity: 0.975;
  }
}

@keyframes frameflicker {
  0% {
    opacity: 1;
  }
  100% {
    opacity: 0.975;
  }
}

/* The Following are media queries */
@media (orientation: portrait) {
  .computerStand {
    height: 15%;
    margin-bottom: 7%;
  }
  .computerStand div {
    height: 35%;
  }
}

@media (max-width: 1100px) {
  .onoff {
    grid-area: 19 / 16/ 21/ 18;
    display: flex;
    justify-content: center;
    align-items: center;
  }
  .button {
    width: 35px;
    height: 30px;
    box-shadow: 0 0 0 4px rgb(0, 0, 0, 0.3);
  }
  .otherButton1 {
    grid-area: 19/14/21/16;
    display: flex;
    justify-content: flex-start;
    align-items: center;
  }
  .otherButton1 div {
    width: 25px;
    height: 25px;
    box-shadow: 0 0 0 3px rgb(0, 0, 0, 0.3);
  }
  .otherButton2 {
    grid-area: 19/12/21/14;
    display: flex;
    justify-content: center;
    align-items: center;
  }
  .otherButton2 div {
    width: 25px;
    height: 25px;
    box-shadow: 0 0 0 3px rgb(0, 0, 0, 0.3);
  }
  .light {
    grid-area: 19/18/21/20;
    display: flex;
    justify-content: flex-start;
    align-items: center;
  }
  .light div {
    width: 15px;
    height: 15px;
    margin: 9px;
  }
}

@media (max-width: 400px) {
  .otherButton2 {
    justify-content: flex-start;
  }
}

@media (max-width: 350px) {
  .button {
    width: 25px;
    height: 20px;
    box-shadow: 0 0 0 4px rgb(0, 0, 0, 0.3);
  }
  .otherButton1 div {
    width: 15px;
    height: 15px;
    box-shadow: 0 0 0 3px rgb(0, 0, 0, 0.3);
  }
  .otherButton2 div {
    width: 15px;
    height: 15px;
    box-shadow: 0 0 0 3px rgb(0, 0, 0, 0.3);
  }
  .light div {
    width: 10px;
    height: 10px;
  }
}
