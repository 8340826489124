.container {
  height: 80%;
  width: 80%;
  background-color: rgb(0, 0, 0);
  border: 1px solid white;
  border-radius: 20px;
  font-family: "VT323", monospace;
  position: relative;
  color: white;
  display: flex;
  justify-content: center;
  align-items: center;
}
.exitBtn {
  height: auto;
  width: 3.5vmax;
  position: absolute;
  top: 5%;
  right: 4%;
  display: flex;
  justify-content: center;
  align-items: center;
  cursor: pointer;
}
.exitBtn img {
  height: 100%;
  width: 100%;
}
.container > h1 {
  position: absolute;
  top: 5%;
  margin: 0;
  left: 4%;
  font-size: 7vmin;
}
.content {
  width: 100%;
  height: 50%;
  display: flex;
  justify-content: space-evenly;
  align-items: center;
}
.content > div {
  height: 100%;
  width: 30%;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
}
.content img {
  width: 40%;
  height: auto;
  animation: 1s bounce infinite alternate;
  cursor: pointer;
}
.content h1 {
  color: white;
  font-size: 5vmin;
  margin: 0;
  animation: 1s bounce infinite alternate;
  cursor: pointer;
}
@keyframes bounce {
  0% {
    transform: translateY(-8%);
  }
  100% {
    transform: translateY(0%);
  }
}

@media (orientation: portrait) {
  .container {
    height: 90%;
    width: 85%;
  }
  .exitBtn {
    top: 3%;
  }
  .container > h1 {
    top: 3%;
    font-size: 4vmax;
    width: 70%;
    overflow-wrap: break-word;
  }
  .content {
    height: 80%;
    flex-direction: column;
  }
  .content > div {
    height: 33%;
    width: 50%;
  }
  .content img {
    height: auto;
    width: 30%;
    min-width: 70px;
    max-width: 250px;
  }
  .content h1 {
    font-size: 4vmax;
  }
}

@media (max-width: 460px) {
  .container > h1 {
    font-size: 4vmax;
    width: 80%;
    overflow-wrap: break-word;
  }
}
@media (max-width: 404px) {
  .content {
    position: absolute;
    top: 15%;
  }
}
